/* eslint-disable import/no-extraneous-dependencies */
import "intersection-observer";
import "requestidlecallback-polyfill";
import smoothScroll from "smoothscroll-polyfill";

// polyfill for scroll and smooth behaviour
smoothScroll.polyfill();

const noop = () => {};
/* eslint-disable */
// Add empty method due to script error
if (!window._AutofillCallbackHandler) {
  window._AutofillCallbackHandler = noop;
}

// Add empty method if Google Analytics  blocked
// Creates an initial ga() function.
// The queued commands will be executed once analytics.js loads.
window.GoogleAnalyticsObject = "ga";
window.ga =
  window.ga ||
  function () {
    // eslint-disable-next-line prefer-rest-params
    (window.ga.q = window.ga.q || []).push(arguments);
  };
// Sets the time (as an integer) this tag was executed.
// Used for timing hits.
window.ga.l = 1 * new Date();

/**
 * String.prototype.replaceAll() polyfill
 * https://gomakethings.com/how-to-replace-a-section-of-a-string-with-another-one-with-vanilla-js/
 * @author Chris Ferdinandi
 * @license MIT
 */
if (!String.prototype.replaceAll) {
	String.prototype.replaceAll = function(str, newStr) {
		if (Object.prototype.toString.call(str).toLowerCase() === '[object regexp]') {
			return this.replace(str, newStr);
		}

		return this.replace(new RegExp(str, 'g'), newStr);
	};
}

// Fix Safari 14
(function() {
	// polyfill for replaceChildren
	if (Node.prototype.replaceChildren === undefined) {
		Node.prototype.replaceChildren = function(addNodes) {
			while(this.lastChild) {
				this.removeChild(this.lastChild);
			}
			if (addNodes !== undefined) {
				this.append(addNodes);
			}
		}
	}

	if (!Array.prototype.at) {
		Array.prototype.at = function (index) {
			// Convert the index to an integer
			index = Math.trunc(index) || 0;

			// Handle negative index
			if (index < 0) {
				index += this.length;
			}

			// Return undefined if the index is out of bounds
			if (index < 0 || index >= this.length) {
				return undefined;
			}

			// Return the element at the given index
			return this[index];
		};
	}
}());

/* polyfill for Element.prototype.matches and Element.prototype.closest */
if (!Element.prototype.matches) {
	Element.prototype.matches = Element.prototype.msMatchesSelector || Element.prototype.webkitMatchesSelector;
}

if (!Element.prototype.closest) {
	Element.prototype.closest = function (s) {
		let el = this;
		if (!document.documentElement.contains(el)) return null;
		do {
			if (el.matches(s)) return el;
			el = el.parentElement || el.parentNode;
		} while (el !== null && el.nodeType === 1);
		return null;
	};
}
